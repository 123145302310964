<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    baseform
  },

  data() {
    return {
      loading :false,
      dataurl:"/contract",
      baseroute:"contract",
      title:"Kontrak",
      fields : [
        { key: 'number', label: 'Nomor Kontrak', type: 'input', rules:'required' },
        { key: 'subject', label: 'Nama Kontrak', type: 'input', rules:'required' },
        { key: 'vendor', label: 'Vendor', type: 'select2', rules:'required', dataurl:'vendor?filter[approval_status]=1', placeholder:'Pilih Vendor' },
        { key: 'date', label: 'Tanggal Kontrak', type: 'date', rules:'required' },
        { key: 'file', label: 'Dokumen Kontrak', type: 'file', rules:'' },
      ]
    }
  },
  // mounted(){
  //   if(this.$route.params.id != null)
  //   this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
  //     this.model = res.data
  //   })
  // },
}
</script>
